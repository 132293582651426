import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { FaHeadphones, FaPlay, FaPause } from 'react-icons/fa';

const WaveformPlayer = ({ audioSrc }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const initializeWaveSurfer = () => {
    if (!isInitialized) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#555',
        progressColor: '#1db954',
        cursorColor: 'transparent',
        barWidth: 2,
        height: 45,
        responsive: true,
      });

      wavesurfer.current.on('ready', () => {
        setIsReady(true);
      });

      wavesurfer.current.on('error', (e) => {
        console.error('Wavesurfer error:', e);
      });

      // Load the initial audio source
      wavesurfer.current.load(audioSrc);

      setIsInitialized(true);
    }
  };

  // Effect that runs whenever audioSrc changes
  useEffect(() => {
    if (isInitialized && wavesurfer.current) {
      wavesurfer.current.load(audioSrc);
      setIsReady(false); // Reset readiness, will be set to true on 'ready' event
    }
  }, [audioSrc, isInitialized]);

  useEffect(() => {
    return () => {
      // Clean up Wavesurfer instance
      if (wavesurfer.current) {
        try {
          wavesurfer.current.destroy();
          wavesurfer.current = null;
        } catch (error) {
          console.error('Error during Wavesurfer cleanup:', error);
        }
      }
    };
  }, []);

  const togglePlay = () => {
    if (!isReady) return;
    setIsPlaying((prev) => !prev);
    wavesurfer.current.playPause();
  };

  return (
    <div
      style={{
        maxWidth: '1000px',
        margin: '0 auto',
        padding: '0px',
        marginTop: '30px',
        marginBottom: '30px',
        textAlign: 'center',
        background: 'transparent',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '50px',
      }}
    >
      {!isInitialized ? (
        <div style={{display: 'flex', gap:'10px', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', color: 'white', fontSize: '20px', height: '22px', marginRight: '0px'}} onClick={initializeWaveSurfer}>
        <FaHeadphones
          style={{
            fontSize: '20px',
            color: 'white',
            cursor: 'pointer',
            marginRight: '0px',
          }}
          
        />
        LISTEN
        </ div>
       
      ) : (
        <button
          onClick={togglePlay}
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            color: 'white',
            marginRight: '0px',
            fontSize: '20px',
            height: '22px',
          }}
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
      )}
      <div
        ref={waveformRef}
        style={{
          flex: 1,
        }}
      ></div>
    </div>
  );
};

export default WaveformPlayer;
